import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {TokenService} from '../../token.service';
import {AuthService} from '../../auth.service';
import {NAVIGATION} from '../../../app.config';

@Injectable()
export class PhaseSelectorAuthGuard implements CanActivate {
  constructor(
    protected readonly router: Router,
    private tokenService: TokenService,
    private authService: AuthService,
  ) {
  }

  async canActivate(): Promise<boolean> {

    let isLoggedIn = false;
    await this.authService.isLoggedIn().then(value => {
      isLoggedIn = value;
    })

    if (isLoggedIn) {
      this.router.navigate([NAVIGATION.DASHBOARD]);
      return false;
    }
    return true;
  }


}
