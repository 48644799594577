import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {TokenService} from '../../token.service';
import {FinalUrls} from './final.urls';
import {SECURITY_GROUPS} from '../../../app.config';

@Injectable()
export class UserAuthGuard implements CanActivate {

  constructor(private _router: Router, private tokenService: TokenService) {
  }

  canActivate(): boolean {
    let url: string = this._router.getCurrentNavigation().finalUrl.toString();
    let status = false;
    const urlParts = url.split('/');
    if (urlParts.length === 4) {
      urlParts.pop();
    }
    url = urlParts.join('/');

    switch (url) {
      case FinalUrls.USER_REGISTRATION:
        if (this.tokenService.hasGroup(SECURITY_GROUPS.G_SUPER_ADMIN) || this.tokenService.hasGroup(SECURITY_GROUPS.G_SYSENSOR_ADMIN)) {
          status = true;
        }
        break;

      case FinalUrls.USER_LIST:
        if (this.tokenService.hasGroup(SECURITY_GROUPS.G_SUPER_ADMIN) || this.tokenService.hasGroup(SECURITY_GROUPS.G_SYSENSOR_ADMIN)) {
          status = true;
        }
        break;
    }

    if (!status) {
      FinalUrls.navigateToDashboard(this._router, url);
    }

    return status;
  }

}
