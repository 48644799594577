import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {AppInfoModel} from '../../model/app.info';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppInfoService {
  private httpClient: HttpClient;

  constructor(private handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }

  public getAppInfo(url): Observable<AppInfoModel> {
    return this.httpClient.get<AppInfoModel>(url);
  }

}
