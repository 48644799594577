import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AdminAPI, DistrictAPI} from '../backend.api.config';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private httpClient: HttpClient) {
  }

  public populateRoles() {
    return this.httpClient.post(AdminAPI.ROLE, null);
  }

  public populateGroups() {
    return this.httpClient.post(AdminAPI.GROUP, null);
  }

}
