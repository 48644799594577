import Swal from 'sweetalert2'

export class SweetAlert {

  public static showMessageOnCenter(message: string, type: Message_Type, time: number = 2000) {
    Swal.fire({
      position: 'center',
      type: type,
      title: message,
      showConfirmButton: false,
      timer: time
    })
  }

  public static showMessageOnTopCorner(message: string, type: Message_Type, time: number = 2000) {
    Swal.fire({
      position: 'top-end',
      toast: true,
      type: type,
      title: message,
      showConfirmButton: false,
      timer: time
    })
  }
}

export enum Message_Type {
  SUCCESS = 'success',
  INFO = 'info',
  ERROR = 'error',
  WARNING = 'warning'
}
