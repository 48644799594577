import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DashboardAPI} from '../backend.api.config';
import {BridgeIssueOngoingSummaryExchange, BridgeIssueStatusSummaryExchange} from '../../model/bridge.issue.status.summary.model';
import {BridgeStatusSummaryExchange} from '../../model/bridge.status.summary.model';
import {BridgeProgressOverviewExchange} from '../../model/bridge.progress.model';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private httpClient: HttpClient) {
  }

  public getBridgeStatusSummary(): Observable<BridgeStatusSummaryExchange> {
    return this.httpClient.get<BridgeStatusSummaryExchange>(DashboardAPI.BRIDGE_STATUS_SUMMARY);
  }

  public getBridgeIssueStatusSummary(): Observable<BridgeIssueStatusSummaryExchange> {
    return this.httpClient.get<BridgeIssueStatusSummaryExchange>(DashboardAPI.BRIDGE_ISSUE_STATUS_SUMMARY);
  }

  public getBridgeIssueOngoingSummary(): Observable<BridgeIssueOngoingSummaryExchange[]> {
    return this.httpClient.get<BridgeIssueOngoingSummaryExchange[]>(DashboardAPI.BRIDGE_ISSUE_DURATION_SUMMARY);
  }

  public getBridgeProgressOverview(): Observable<BridgeProgressOverviewExchange> {
    return this.httpClient.get<BridgeProgressOverviewExchange>(DashboardAPI.BRIDGE_PROGRESS_OVERVIEW);
  }
}
