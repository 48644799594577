import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {SubContractorModel} from '../../model/sub.contractor.model';
import {SubContractorAPI} from '../backend.api.config';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubContractorService {

  constructor(private _http: HttpClient) {

  }

  public saveSubContractor(subContractor: SubContractorModel) {
    return this._http.post(SubContractorAPI.SAVE + '/', subContractor);
  }

  public updateSubContractor(subContractor: SubContractorModel) {
    return this._http.post(SubContractorAPI.UPDATE + '/', subContractor);
  }

  public deleteSubContractor(subContractorName: string) {
    const params = new HttpParams().set('subContractorName', subContractorName);
    return this._http.delete(SubContractorAPI.GET, {params: params});
  }

  public listSubContractor(name, mobile, subContractorStatus): Observable<SubContractorModel[]> {
    const params = new HttpParams().set('name', name).set('mobile', mobile).set('subContractorStatus', subContractorStatus);
    return this._http.get<SubContractorModel[]>(SubContractorAPI.LIST, {params: params});
  }

  public listSubContractorName(): Observable<String[]> {
    return this._http.get<String[]>(SubContractorAPI.LIST_NAME);
  }

  public getSubContractor(name: String): Observable<SubContractorModel> {
    return this._http.get<SubContractorModel>(SubContractorAPI.GET + name);
  }
}
