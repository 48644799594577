import {Injectable} from '@angular/core';
import {BridgeModel} from '../../model/bridge.model';
import jsPDF from 'jspdf';


@Injectable({
  providedIn: 'root'
})
export class PDFExportService {

  constructor() {
  }

  public exportBridgeDetailPDF(bridge: BridgeModel) {

    const doc = new jsPDF('p', 'pt');

    doc.setFontSize(25);
    doc.setFontType('bold');
    doc.text(230, 50, 'Janson Bridging');
    doc.setFontSize(14);
    doc.setFontType('normal');
    doc.text(280, 75, 'Bridge Detail');
    doc.setLineWidth(0.5);
    doc.line(280, 77, 360, 77);
    doc.setFontSize(12);

    doc.text(60, 150, 'Bridge No');
    doc.text(160, 150, '-');
    doc.text(180, 150, bridge.no);

    doc.text(60, 170, 'Bridge Name');
    doc.text(160, 170, '-');
    doc.text(180, 170, bridge.name);

    doc.text(60, 190, 'Unit');
    doc.text(160, 190, '-');
    doc.text(180, 190, bridge.province);

    doc.text(60, 210, 'DS Division');
    doc.text(160, 210, '-');
    doc.text(180, 210, bridge.dsDivision);

    doc.text(60, 230, 'GN Division');
    doc.text(160, 230, '-');
    doc.text(180, 230, bridge.gnDivision);

    doc.text(60, 250, 'Span');
    doc.text(160, 250, '-');
    doc.text(180, 250, String(bridge.span));

    doc.text(60, 270, 'Status');
    doc.text(160, 270, '-');
    doc.text(180, 270, bridge.bridgeStatus);

    doc.text(60, 290, 'Sub contractor');
    doc.text(160, 290, '-');
    doc.text(180, 290, bridge.subContractor == null ? '-' : bridge.subContractor);

    doc.text(60, 310, 'Remarks');
    doc.text(160, 310, '-');
    doc.text(180, 310, bridge.remarks);

    doc.setFontType('bold');
    doc.text(60, 370, 'Locations');

    doc.setFontType('normal');
    doc.text(60, 390, 'North Degree');
    doc.text(160, 390, '-');
    doc.text(180, 390, String(bridge.degreeNorth));

    doc.text(280, 390, 'East Degree');
    doc.text(380, 390, '-');
    doc.text(400, 390, String(bridge.degreeEast));

    doc.text(60, 410, 'North Minute');
    doc.text(160, 410, '-');
    doc.text(180, 410, String(bridge.minuteNorth));

    doc.text(280, 410, 'East Minute');
    doc.text(380, 410, '-');
    doc.text(400, 410, String(bridge.minuteEast));

    doc.text(60, 430, 'North Second');
    doc.text(160, 430, '-');
    doc.text(180, 430, String(bridge.secondNorth));

    doc.text(280, 430, 'East Second');
    doc.text(380, 430, '-');
    doc.text(400, 430, String(bridge.secondEast));

    doc.text(60, 520, 'Completed Date');
    doc.text(180, 520, '-');
    doc.text(200, 520, bridge.format4 === null ? '-' : bridge.format4);

    doc.text(60, 540, 'Handed Over Date');
    doc.text(180, 540, '-');
    doc.text(200, 540, bridge.toc === null ? '-' : bridge.toc);
    doc.text(60, 560, 'EIA & SIA');
    doc.text(180, 560, '-');
    doc.text(200, 560, bridge.eiaSia === null ? '-' : bridge.eiaSia);

    doc.text(60, 720, '...............................');
    doc.text(60, 735, 'Prepared by');

    doc.save('bridge_detail' + bridge.no + '.pdf');
  }
}
