export enum BRIDGE_NAVIGATION {
  NEW_BRIDGE = 'new-bridge',
  RENEW = 'renew',
  LIST = 'list',
  RENEW_LIST = 'renew-list',
  PROGRESS = 'progress',
  PROGRESS_NOT_REPORTED = 'progress-not-reported',
  ISSUE = 'issue',
  SPAN_MASTER = 'span-master',
}
