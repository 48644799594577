export class AppCommonMethods {
  public capitalizeFirstLetter(camelCaseWord: string): string {
    if (camelCaseWord.length === 0) {
      return camelCaseWord;
    }
    const separatedWords = camelCaseWord.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/\./g, ' ');
    return separatedWords.charAt(0).toUpperCase() + separatedWords.slice(1);

  }

}
