import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Message_Type, SweetAlert} from '../../sweet.alert';
import {AuthenticationAPI} from '../../backend.api.config';

@Injectable()
export class NotfoundResponseInterceptor implements HttpInterceptor {

  private SERVICE_NOT_FOUND = 'Service not found, Please check with administration!';

  constructor(private router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(evt => {
        //return of(evt)
      }),
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse) {
          //TODO: fix here

          // if (!error.url.includes(AuthenticationAPI.SIGNIN)) {
          //   if (error.status === 0 || error.status === 404) {
          //     SweetAlert.showMessageOnTopCorner(this.SERVICE_NOT_FOUND, Message_Type.ERROR);
          //   }
          // }

          return next.handle(req);
        }
      })
    );
  }

}
