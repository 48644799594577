import {NgModule} from '@angular/core';

import {AccordionAnchorDirective, AccordionDirective, AccordionLinkDirective} from './accordion';
import {ToggleFullscreenDirective} from './fullscreen/toggle-fullscreen.directive';

@NgModule({
  declarations: [AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective, ToggleFullscreenDirective],
  exports: [AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective, ToggleFullscreenDirective],
  providers: []
})
export class SharedModule {
}
