export class UserModel {
  id = '';
  unitId = '0';
  firstName = '';
  lastName = '';
  email = '';
  phone = '';
  username = '';
  password = '';
  refNo = '';
  designation = '0';
  enabled = true;
  realmRoles: String[] = [];
  attributes;
  userRole: String = '0';
  credentialsNonExpired = true;

  clientRoles;
  notBefore = 0;
  base64Image = '';
  access;
  groups = [];
  requiredActions = [];
  disableableCredentialTypes = [];
  serviceAccountClientId = '';
  federationLink = '';
  emailVerified = false;
  createdTimestamp = 0;
  origin = '';
  self = '';
}

export class UserAttributeValue {
  attributeName = '';
  value = '';
}

export enum User_Attribute_Name {
  UNIT_ID = 'UNIT_ID',
  DESIGNATION = 'DESIGNATION',
  REF_ID = 'REF_ID',
  PHONE = 'PHONE'
}


