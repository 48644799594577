import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {AppInfoModel} from '../../model/app.info';

@Component({
  selector: 'app-layout',
  template: '<router-outlet></router-outlet>'
})
export class AuthLayoutComponent implements OnInit {

  constructor(private titleService: Title) {
  }

  ngOnInit(): void {
    this.getAppInfo();
  }

  getAppInfo() {
    const appInfo: AppInfoModel = JSON.parse(sessionStorage.getItem('appInfo'));
    this.titleService.setTitle(appInfo.title);
  }
}
