import {Injectable} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';

@Injectable({providedIn: 'root'})
export class SpinnerService {
  constructor(private spinnerService: NgxSpinnerService) {
  }

  public openSpinner() {
    this.spinnerService.show();
  }

  public hideSpinner() {
    this.spinnerService.hide();
  }
}
