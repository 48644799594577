import {Injectable} from '@angular/core';
import {IExchangeLogin} from '../../authentication/expose-out/model/exchange.user';
import {BadgeItem, ChildrenItem, Menu} from '../../layouts/expose-out/model/menu';
import {IExchangeUpdateUser} from '../../model/security/user.update.model';
import {UserModel} from '../../model/security/user.model';

@Injectable()
export class ConfigService {
  public getEmptyInstanceOfIExchangeLogin(): IExchangeLogin {
    const user: IExchangeLogin = {
      username: '',
      password: '',
      firstName: '',
      lastName: '',
      jwt: '',
      hasError: false,
      errorMessage: '',
      roles: [],
      expiration: 0,
      phone: ''
    };
    return user;
  }

  public getEmptyInstanceOfMenuWithoutChildren(): Menu {
    const menu: Menu = {
      state: '',
      name: '',
      type: '',
      icon: '',
    };
    return menu;
  }

  public getEmptyInstanceOfMenuWithChildren(): Menu {
    const menu: Menu = {
      state: '',
      name: '',
      type: '',
      icon: '',
      children: [{state: '', name: ''}]
      // children: [{state: '', name: '', paramValue: ''}]
    };
    return menu;
  }

  public getEmptyInstanceOfBadgeItem(): BadgeItem {
    const badgeItem: BadgeItem = {
      type: '',
      value: ''
    };
    return badgeItem;
  }

  public getEmptyInstanceOfChildrenItem(): ChildrenItem {
    const childrenItem: ChildrenItem = {
      state: '',
      name: '',
      // paramValue: ''
    };
    return childrenItem;
  }

  public getInstanceOfIExchangeUpdateUser(): IExchangeUpdateUser {
    const userUpdate: IExchangeUpdateUser = {
      userAttributesToUpdate: [],
      userExchange: new UserModel(),
      currentPassword: ''
    };
    return userUpdate;
  }
}
