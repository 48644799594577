import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BridgeAPI, RenewBridgeAPI} from '../backend.api.config';
import {Observable} from 'rxjs';
import {RenewBridgeModel} from '../../model/renew.bridge.model';
import {BridgeDocument} from '../../model/bridge.model';

@Injectable({
  providedIn: 'root'
})
export class RenewBridgeService {

  constructor(private http: HttpClient) {
  }

  public saveRenewBridge(_bridge: RenewBridgeModel) {
    return this.http.post(RenewBridgeAPI.SAVE, _bridge);
  }

  public updateRenewBridge(_bridge: RenewBridgeModel) {
    return this.http.post(RenewBridgeAPI.UPDATE, _bridge);
  }

  public deleteRenewBridge(bridgeNo) {
    const params = new HttpParams().set('bridgeNo', bridgeNo);
    return this.http.delete(RenewBridgeAPI.GET, {params: params});
  }

  public listRenewBridge(currentBridgeNo, newBridgeNo, bridgeName, bridgeStatus, unitId): Observable<RenewBridgeModel[]> {
    const params = new HttpParams().set('currentBridgeNo', currentBridgeNo).set('newBridgeNo', newBridgeNo).set('bridgeName', bridgeName).set('bridgeStatus', bridgeStatus).set('unitId', unitId);
    return this.http.get<RenewBridgeModel[]>(RenewBridgeAPI.LIST, {params: params});
  }

  public listRenewBridgeNos() {
    return this.http.get(RenewBridgeAPI.LIST_NO);
  }

  public getRenewBridge(bridgeNo: String): Observable<RenewBridgeModel> {
    return this.http.get<RenewBridgeModel>(RenewBridgeAPI.GET + bridgeNo);
  }

  public transferRenewBridgeToBridge(_bridge: RenewBridgeModel) {
    return this.http.post(RenewBridgeAPI.TRANSFER, _bridge);
  }

  public updateBridgeDocuments(bridgeDocument: BridgeDocument) {
    return this.http.post(RenewBridgeAPI.UPDATE_ATTACHMENT, bridgeDocument);
  }

  public getBridgeDocument(bridgeNo, documentType): Observable<BridgeDocument> {
    const params = new HttpParams().set('bridge-no', bridgeNo).set('document-type', documentType);
    return this.http.get<BridgeDocument>(RenewBridgeAPI.GET_DOCUMENT, {params: params});
  }
}
